import styled from "styled-components";

export const Container = styled.div`
    width: 85%;
    margin: auto;
    padding: 15px;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const Title = styled.h2`
    margin: 0px;
    margin-left: 15px;
    font-size: 1.4em;
    font-weight: bold;
    color: var(--text-title);
`;

export const Image = styled.img`
    min-width: 60px;
    width: 60px;
    height: 60px;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    cursor: pointer;
    object-fit: cover;
`;

export const ImageFullscreenContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ImageFullscreen = styled.img`
    box-sizing: border-box;
    border-radius: var(--border-radius);
    cursor: pointer;
    width: auto;
    height: auto;
    max-width: 90%;
    max-height: 90%;
`;

export const ImageFullscreenCaption = styled.div`
    position: absolute;
    bottom: 10px;
    color: white;
    width: 90%;
    margin-left: 5%;
    box-shadow: var(--shadow);
    margin-right: 5%;
    text-align: center;
    font-weight: 200;
`;

export const Description = styled.p`
`;

export const SectionTitle = styled.h3`
    margin-top: 0px;
    font-weight: bold;
    color: var(--text-title);
`;

export const FunFactsContainer = styled.div`
    background: var(--background-secondary);
    margin: auto;
    border-radius: var(--border-radius);
    padding: 10px;
    box-shadow: var(--shadow);
`;

export const FunFact = styled.div`
    margin: 5px;
`;

export const LinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    ${SectionTitle} {
        margin-bottom: 10px;
    }
`;

export const LinksRow = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    width: 100%;
    white-space: nowrap;
    margin-left: -15px;
    padding-left: 15px;
    padding-right: 15px;
`;
