import React, { useEffect, useState } from 'react';
import { collection, getDoc, doc } from "firebase/firestore"
import { Container, Description, FunFact, FunFactsContainer, SectionTitle, Image, ImageFullscreen, ImageFullscreenCaption, ImageFullscreenContainer, LinksContainer, Title, TitleContainer, LinksRow } from './styles';
import { useDB } from '../../hooks/useDB';
import { useParams } from 'react-router-dom';
import { Subject } from '../../types/subject';
import LinkView from './LinkView';

const SubjectComp = () => {
    const { id } = useParams();
    const db = useDB();

    const [subject, setSubject] = useState<Subject>()
    const [isImageFullscreen, setIsImageFullscreen] = useState(false);

    useEffect(() => {
        getDoc(doc(collection(db, 'subjects'), id))
            .then(snap => {
                if (snap.data() == null) return;

                const sub: Subject = JSON.parse(JSON.stringify(snap.data()!))
                setSubject(sub)
            })
    }, [id, db])

    const handleImageClick = () => {
        setIsImageFullscreen(!isImageFullscreen);
    };

    return (
        <>
            {subject &&
                <Container>
                    <TitleContainer>
                        <Image src={subject.image_url} onClick={handleImageClick} />
                        <Title>{subject.model_response.full_name}</Title>
                    </TitleContainer>
                    <Description>{subject.model_response.short_description}</Description>
                    <Description>{subject.model_response.description}</Description>

                    <FunFactsContainer>
                        <SectionTitle>💡 Fun Facts</SectionTitle>
                        {subject.model_response.fun_facts.map(ff => (
                            <FunFact>• <b>{ff.title}: </b>{ff.description}</FunFact>
                        ))}
                    </FunFactsContainer>

                    <LinksContainer>
                        <SectionTitle>🔗 Related Links</SectionTitle>
                        <LinksRow>
                            {subject.model_response.links.map((link, index) => (
                                <LinkView key={index} link={link} />
                            ))}
                        </LinksRow>
                    </LinksContainer>

                    {isImageFullscreen && (
                        <ImageFullscreenContainer>
                            <ImageFullscreen src={subject.image_url} onClick={handleImageClick} />
                            <ImageFullscreenCaption>{subject.model_response.full_name}</ImageFullscreenCaption>
                        </ImageFullscreenContainer>
                    )}
                </Container>
            }
        </>
    );
}

export default SubjectComp;
